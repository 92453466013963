@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;500;700&display=swap');

/* Global variables */
:root {
  --main-font: 'Oswald', sans-serif;
  --font-light: 200;
  --font-regular: 400;
  --font-medium: 500;
  --font-bold: 700;

  --standart-transition: all 100ms ease;

  --regular-black: #000000;
  --regular-white: #fff;
  --accent-color: #ff7d00;
  --main-grey: #a0a0a0;
  --error-color: #ff5f5f;
  --success-color: #00e168;
  --shadow-color: #00000038;

  --theme-color: #92c1db;
  --theme-lighter: #e1e1ea;
  --background-color: #fff;
  --font-color: #000;
  --hover-color: #92c1db;
}

/* Global variables for dark mode */
[data-theme='dark'] {
  --theme-color: #010409;
  --theme-lighter: #161d21;
  --background-color: #282c34;
  --font-color: #fff;
  --hover-color: #e1e1ea;
}

html {
  font-family: var(--main-font);
}

body {
  background-color: var(--background-color);
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 37px;
  font-weight: var(--font-light);
  color: var(--main-grey) !important;
}

.mobile-h3 {
  font-size: 29px;
}

h4 {
  font-size: 24px;
  font-weight: var(--font-regular);
}

h5 {
  font-size: 21px;
}

p {
  font-size: 18px;
  font-weight: var(--font-light);
  line-height: 1.5;
}

small {
  font-size: 10px;
  font-weight: var(--font-light);
}

h1,
h2,
h3,
h4,
h5,
p,
small,
.mobile-h3 {
  color: var(--font-color);
}

::placeholder {
  font-weight: var(--font-light);
  color: var(--main-grey);
}

.text-link {
  text-decoration: none;
  color: var(--font-color);
  font-weight: var(--font-regular);
  border-bottom: 2px solid var(--accent-color);
}

.text-link:hover {
  background-color: var(--accent-color);
}
