/* The main container that puts elements in their positions */
.page-container {
  display: grid;
  grid-template-rows: 85px 1fr 40px;
  grid-template-columns: 75px 1fr;
  min-height: 100vh;
}

::selection {
  background-color: var(--accent-color);
}

/* Header section */
.main-header {
  background-color: var(--theme-color);
  grid-row: 1/2;
  grid-column: 2/3;
  display: flex;
  align-items: center;
  box-shadow: 0px 5px 10px var(--shadow-color);
}

.avatar-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-left: 35px;
}

.main-header h2 {
  font-weight: var(--font-light);
  margin-left: 20px;
  cursor: default;
  padding-bottom: 5px;
}

/* Container with navbar */
.aside-navbar {
  background-color: var(--theme-lighter);
  grid-row: 1/-1;
  grid-column: 1/2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 100;
}

.aside-navbar nav {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

nav a {
  color: var(--font-color);
  width: 59px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  position: relative;
}

nav a:hover {
  border-left: 8px solid var(--hover-color);
}

nav a.active-page {
  border-left: 8px solid var(--accent-color);
}

#home-button:hover::after {
  content: '/home';
}

#projects-button:hover::after {
  content: '/projects';
}

#about-button:hover::after {
  content: '/about';
}

#contact-button:hover::after {
  content: '/contact';
}

#home-button:hover::after,
#projects-button:hover::after,
#about-button:hover::after,
#contact-button:hover::after {
  font-size: 10px;
  font-weight: var(--font-light);
  position: absolute;
  bottom: 3px;
  left: 50%;
  transform: translateX(-50%);
  /* animation: nav-button-text 200ms; */
}

/* @keyframes nav-button-text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */

.nav-link {
  border-bottom: none;
}

.nav-icon {
  width: 35px;
  height: auto;
}

/* Main content container */
.main-content {
  grid-row: 2/3;
  grid-column: 2/3;
}

/* Footer section */
.main-footer {
  background-color: var(--theme-color);
  grid-row: 3/4;
  grid-column: 2/3;
  box-shadow: 0px -5px 10px var(--shadow-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
}

.footer-socials,
.color-mode-icon {
  display: flex;
}

.footer-socials a {
  display: flex;
  margin: 0 10px;
}

.footer-icon {
  height: 25px;
  width: 25px;
  color: var(--font-color);
}

.color-mode-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 23px;
  width: 23px;
  border: 2px solid var(--background-color);
  border-radius: 50%;
  background-color: var(--background-color);
  cursor: pointer;
}

.color-mode-icon {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: var(--background-color);
  color: var(--accent-color);
}

/* Media queries for mobile */
@media screen and (max-width: 1024px) {
  .page-container {
    grid-template-rows: 85px 65px 1fr 40px;
    grid-template-columns: 1fr;
  }

  .main-header {
    grid-row: 1/2;
    grid-column: 1/2;
  }

  .aside-navbar {
    position: sticky;
    grid-row: 2/3;
    grid-column: 1/2;
    box-shadow: 0px 5px 10px var(--shadow-color);
  }

  .aside-navbar nav {
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 35px;
  }

  .main-content {
    grid-row: 3/4;
    grid-column: 1/-1;
  }

  .main-footer {
    grid-row: 4/5;
    grid-column: 1/2;
    box-shadow: 0px -5px 10px var(--shadow-color);
  }

  nav a {
    width: 59px;
    height: 49px;
    border: none;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
  }

  nav a:hover {
    border: none;
    border-top: 8px solid transparent;
    border-bottom: 8px solid var(--hover-color);
  }

  nav a.active-page {
    border: none;
    border-top: 8px solid transparent;
    border-bottom: 8px solid var(--accent-color);
  }

  #home-button:hover::after,
  #projects-button:hover::after,
  #about-button:hover::after,
  #contact-button:hover::after {
    display: none;
  }
}

@media screen and (max-width: 645px) {
  .main-header h2 {
    font-size: 37px;
  }
}

@media screen and (max-width: 300px) {
  .main-header h2 {
    font-size: 24px;
    padding: 0;
  }
}
