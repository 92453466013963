.contact-page-ctr {
  position: relative;
  margin: auto;
  text-align: center;
  max-width: 1000px;
  padding-top: 30px;
  padding-bottom: 45px;
}

.contact-page-ctr h1 {
  margin: 0;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: auto;
  margin-top: 35px;
  width: 50%;
}

.MuiTextField-root {
  background-color: var(--regular-white);
}

@media screen and (max-width: 1024px) {
  .contact-form {
    width: 70%;
  }
}

@media screen and (max-width: 650px) {
  .contact-form {
    width: 85%;
  }
}

/* 
.message-status-modal-ctr {
  background-color: var(--success-color);
  padding: 25px 50px;
  z-index: 100;
  opacity: 0;
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 5px 10px var(--shadow-color);
  transition: var(--standart-transition);
}

.message-status-modal-ctr h4 {
  margin: 0;
  margin-bottom: 15px;
  color: var(--regular-black);
}

.message-status-modal-ctr p {
  margin: 0;
  color: var(--regular-black);
}

.message-status-modal-ctr.active {
  top: 50%;
  opacity: 1;
} */
