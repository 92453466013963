.home-page-ctr {
  width: 50%;
  margin-left: 20%;
  margin-top: 7%;
}

.home-page-ctr h5 {
  margin-bottom: 20px;
}

.skill-icon {
  width: 30px;
  height: auto;
  margin-right: 20px;
  margin-bottom: 20px;
  color: var(--font-color);
}

@media screen and (max-width: 1024px) {
  .home-page-ctr {
    width: auto;
    margin: 10% 15%;
  }
}

@media screen and (max-width: 645px) {
  .home-page-ctr {
    width: auto;
    margin: 10% 5%;
  }

  .skill-icon {
    margin-right: 30px;
  }
}
