.about-page-ctr {
  margin: auto;
  text-align: center;
  max-width: 1000px;
  padding-top: 30px;
  padding-bottom: 45px;
}

.about-page-ctr h1 {
  margin: 0;
}

.about-page-ctr h3 {
  margin-top: 15px;
  padding: 0px 35px;
}

.about-page-ctr p {
  font-size: 26px;
  margin: 0 35px;
}

.about-text-ctr {
  text-align: left;
}

@media screen and (max-width: 530px) {
  .about-page-ctr p {
    font-size: 21px;
  }
}
