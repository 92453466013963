.error-page-ctr {
  margin: auto;
  text-align: center;
  max-width: 1000px;
  padding-top: 30px;
  padding-bottom: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error-page-ctr h1 {
  margin: 0;
}

.error-page-ctr h3 {
  margin-top: 15px;
  padding: 0px 35px;
}

.error-page-ctr img {
  margin-bottom: 25px;
  max-width: 350px;
}

@media screen and (max-width: 450px) {
  .error-page-ctr img {
    max-width: 300px;
  }
}

@media screen and (max-width: 300px) {
  .error-page-ctr img {
    max-width: 250px;
  }
}
