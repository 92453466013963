.projects-page-ctr {
  margin: auto;
  text-align: center;
  max-width: 1000px;
  padding-top: 30px;
  padding-bottom: 45px;
}

.projects-page-ctr h1 {
  margin: 0;
}

.projects-page-ctr h3 {
  margin-top: 15px;
  padding: 0px 35px;
}

.all-projects-container {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: repeat(4, 1fr);
  gap: 25px;
}

@media screen and (max-width: 1124px) {
  .all-projects-container {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
    gap: 35px;
    padding: 0px 35px;
  }
}

@media screen and (max-width: 768px) {
  .all-projects-container {
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
    padding: 0px 35px;
  }
}

@media screen and (max-width: 530px) {
  .all-projects-container {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    padding: 0px 15px;
  }

  .projects-page-ctr h3 {
    padding: 0px 15px;
  }
}
